// eslint-disable-next-line
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './styles/style.css'

// Ensure GSAP plugins are ready
gsap.registerPlugin(ScrollTrigger)

// Function to initialize animations
function initStaggerAnimation() {
  const triggers = document.querySelectorAll('[stagger-trigger]')

  // Check if any trigger elements are found
  if (triggers.length === 0) {
    console.warn('No elements with attribute "stagger-trigger" found.')
    return // Exit if no triggers are found
  }

  triggers.forEach((trigger) => {
    const items = trigger.querySelectorAll('[stagger-me]')

    // Check if items are found for each trigger
    if (items.length === 0) {
      console.warn(
        `No elements with attribute "stagger-me" found in trigger:`,
        trigger
      )
      return // Skip this trigger if no items are found
    }

    const itemCount = items.length

    const baseDuration = trigger.hasAttribute('duration')
      ? parseFloat(trigger.getAttribute('duration'))
      : 0.4

    const totalDuration = baseDuration * itemCount

    const delay = trigger.hasAttribute('delay')
      ? parseFloat(trigger.getAttribute('delay'))
      : 0

    const offset = trigger.hasAttribute('offset')
      ? trigger.getAttribute('offset')
      : 'top 80%'

    gsap.from(items, {
      scrollTrigger: {
        trigger: trigger,
        start: offset, // Dynamic offset
        toggleActions: 'play none none none', // Play the animation on scroll
      },
      y: 20, // Slide from bottom (20px)
      opacity: 0, // Fade in from opacity 0
      stagger: 0.2,
      duration: totalDuration, // Duration per item remains consistent
      delay: delay, // Apply delay from attribute
      ease: 'power1.out', // Ease type
    })
  })
}

// Initialize stagger animation when the DOM is fully loaded
document.addEventListener('DOMContentLoaded', initStaggerAnimation)

// Function to initialize blur animations
function initBlurAnimation() {
  const triggers = document.querySelectorAll('[blur-trigger]')

  // Check if any blur-trigger elements are found
  if (triggers.length === 0) {
    console.warn('No elements with attribute "blur-trigger" found.')
    return // Exit if no triggers are found
  }

  triggers.forEach((trigger) => {
    const items = trigger.querySelectorAll('[blur-me]')

    // Check if blur-me items are found for each trigger
    if (items.length === 0) {
      console.warn(
        `No elements with attribute "blur-me" found in trigger:`,
        trigger
      )
      return // Skip this trigger if no items are found
    }

    const itemCount = items.length // Get the number of staggered items

    // Get the base duration from the attribute, default to 0.5 if not provided
    const baseDuration = trigger.hasAttribute('duration')
      ? parseFloat(trigger.getAttribute('duration'))
      : 0.5

    const totalDuration = baseDuration * itemCount // Total duration is proportional to the number of items

    // Get the delay from the attribute, default to 0 if not provided
    const delay = trigger.hasAttribute('delay')
      ? parseFloat(trigger.getAttribute('delay'))
      : 0

    // Get the offset from the attribute, default to 'top 80%' if not provided
    const offset = trigger.hasAttribute('offset')
      ? trigger.getAttribute('offset')
      : 'top 80%'

    // GSAP animation using ScrollTrigger with blur filter
    gsap.from(items, {
      scrollTrigger: {
        trigger: trigger,
        start: offset, // Dynamic offset
        toggleActions: 'play none none none', // Play the animation on scroll
      },
      filter: 'blur(10px)', // Start with a blur of 10px
      opacity: 0, // Optionally fade in with the blur
      stagger: 0.2,
      duration: totalDuration, // Duration per item remains consistent
      delay: delay, // Apply delay from attribute
      ease: 'power3.out', // Ease type
      clearProps: 'filter,opacity', // Ensure filter property is cleared after the animation
    })
  })
}

// Initialize blur animation when the DOM is fully loaded
document.addEventListener('DOMContentLoaded', initBlurAnimation)
